<script>
    import Base from '@backend/Base.vue';
    import BOGen from '@helper/BOGen';

    export default {
        name: "AppUserRegister",
        extends: Base,
        data() {
            return {
                Name: "AppUserRegister",
                mrLevel: {},
                input: {},
                contact: {},
                AppContacts: [],
            }
        },
        mounted() {
            this.$set(this.$root, 'page', this)
            this.refreshData()
        },
        methods: {
            viewUser(id) {
                $("#detail_user").modal()
                BOGen.apirest("/" + this.Name, {
                    id: id,
                    type: 'viewUser'
                }, (err, resp) => {
                    if (err) return swal(err.resp.message, '', 'warning')
                    this.contact = resp.data
                }, "POST")
            },
        },
        watch: {
            '$route.query'() {
                this.refreshData()
            },
            'filter.level'() {
                this.search()
            },
        }
    };
</script>

<template>
    <div class="container-fluid">
        <PageTitle></PageTitle>

        <!-- ======================================================================== 
            LIST DATA
        ============================================================================= -->
        <div class="row" v-if="!$route.params.id">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <VForm @resp="search">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h5 class="card-title">{{ObjectName}} List </h5>
                                </div>
                                <div class="col-sm-3"></div>
                                <div class="col-sm-3">
                                    <div class="form-group mb-0">
                                        <div class="input-group">
                                            <input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
                                            <div class="input-group-append">
                                                <button class="btn btn-info" type="button" @click="search()"><i
                                                        class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                                </div>
                            </div>
                        </VForm>
                    </div>
                    <div class="table-responsive ajax-table">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <SortField name="au_full_name"></SortField>
                                    </th>
                                    <th>{{fields.au_email_address}}</th>
                                    <th>{{fields.au_phone}}</th>
                                    <th>{{fields.au_is_active}}</th>
                                    <th width="120px">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in data.data" :key="k">
                                    <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                    <td>{{v.au_full_name}}</td>
                                    <td>{{v.email}}</td>
                                    <td>{{v.mobile}}</td>
                                    <td v-if="v.au_is_active == 'N'">
                                        <label class="label label-warning">Unverified</label>
                                    </td>
                                    <td v-if="v.au_is_active == 'Y'">
                                        <label class="label label-success">Verified</label>
                                    </td>
                                    <td class="btn-action text-center">
                                        <a href="javascript:;" class="icon_action" @click="viewUser(v.id)" v-tooltip="'View Detail'"><i class="ti-eye"></i></a>
									    <a href="javascript:;" @click="deleteItem($event,k)" class="text-inverse icon_action act_icon" title="" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash"></i></a>
                                    </td>
                                </tr>
                                <tr v-if="NotFound">
                                    <td colspan="99">
                                        <h3 class="tc">{{NotFound}}</h3>
                                    </td>
                                </tr>
                                <tr v-if="data.data===false">
                                    <td colspan="99">
                                        <LoadingSpinner light></LoadingSpinner>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="panel-body">
                        <div class="pull-right">
                            <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ======================================================================== 
            FORM CRUD   
        ============================================================================= -->
        <div id="detail_user" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="prod_cat">Detail User</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Type</label>
                                            <p>{{contact.tipe}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">Fullnane</label>
                                            <p>{{contact.au_full_name}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">Phone</label>
                                            <p>{{contact.mobile}}</p>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label class="control-label">Address</label>
                                            <p>{{contact.address}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">Province</label>
                                            <p>{{contact.province}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">District</label>
                                            <p>{{contact.district}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">Postal Code</label>
                                            <p>{{contact.postalcode}}</p>
                                        </div> -->
                                    </div>
                                    <div class="col-md-6">      
                                        <div class="form-group">
                                            <label class="control-label">Register Date</label>
                                            <p>{{contact.register}}</p>
                                        </div>                          
                                        <div class="form-group">
                                            <label class="control-label">Email</label>
                                            <p>{{contact.au_email_address}}</p>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label class="control-label">City</label>
                                            <p>{{contact.city}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label">Sub District</label>
                                            <p>{{contact.subdistrict}}</p>
                                        </div> -->
                                        <div class="form-group">
                                            <label class="control-label">Status</label>
                                            <p>
                                                <label v-if="contact.au_is_active == 'N'" class="label label-warning">Unverified</label>
                                                <label v-if="contact.au_is_active == 'Y'" class="label label-success">Verified</label>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>